import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "./../assets/logo.svg";
import { AiOutlineClose } from "react-icons/ai";
import { links, navigateToTop, navigateToSpecificComponent } from "./../utils";

function Sidebar({ isSidebarOpen, closeSidebar }) {
  useEffect(() => {
    // for toggle sidebar
    isSidebarOpen
      ? document.body.classList.add("body-no-scroll")
      : document.body.classList.remove("body-no-scroll");
  }, [isSidebarOpen]);

  return (
    <Wrapper className="sidebar">
      <div
        className={`${
          isSidebarOpen ? "main-sidebar show-sidebar" : "main-sidebar"
        }`}
      >
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <Link
              to="/"
              tabIndex={isSidebarOpen ? 0 : -1}
              onClick={() => {
                closeSidebar();
                navigateToTop();
              }}
            >
              <img src={Logo} alt="sidebar logo" />
            </Link>
          </div>
          <button
            className="close-btn"
            onClick={() => closeSidebar()}
            tabIndex={isSidebarOpen ? 0 : -1}
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="sidebar-content">
          <ul className="sidebar-links">
            {links.map((eachLink) => {
              return (
                <li key={eachLink.id}>
                  <Link
                    to={eachLink.path}
                    tabIndex={isSidebarOpen ? 0 : -1}
                    onClick={(e) => {
                      closeSidebar();
                      navigateToSpecificComponent(e);
                    }}
                  >
                    {eachLink.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.aside`
  .main-sidebar {
    width: 100%;
    height: 100vh;
    background: var(--clr-white);
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 20px;
    transition: var(--transition);
    transform: translateX(-100%);
    z-index: 10;
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .show-sidebar {
    transform: translateX(0%);
    opacity: 1;
  }
  .sidebar-header {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sidebar-logo img {
      width: 100px;
    }

    .close-btn {
      border: none;
      background: transparent;
      svg {
        font-size: 24px;
        color: var(--clr-red-dark);
      }
    }
  }
  .sidebar-content {
    .sidebar-links {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      place-items: center;

      a {
        font-size: 16px;
        color: var(--clr-black);
      }
    }
  }
`;

export default Sidebar;
