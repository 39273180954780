import React from "react";
import styled from "styled-components";

function Button({
  title = "add button name",
  type = "primary",
  disabled = false,
  handleChange,
  id,
  showBotomInfo,
  infoType,
}) {
  return (
    <Wrapper>
      <button
        type="submit"
        className={type}
        disabled={disabled}
        onClick={handleChange}
        id={id}
      >
        {title}
      </button>
      {showBotomInfo && (
        <p className={`info-message ${infoType}`}>{showBotomInfo}</p>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .info-message {
    color: red;
    font-size: 12px;
    margin-top: 10px;
    font-weight: bold;
  }
  button {
    height: 45px;
    min-width: 130px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: var(--font-weight-500);

    &.primary {
      color: var(--clr-white);
      background: linear-gradient(var(--clr-primary), var(--clr-primary-light));
      border: 1px solid var(--clr-primary);
    }

    &.secundary {
      color: var(--clr-primary);
      background: var(--clr-white);
      border: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 40%;
      /* pointer-events: none; */
    }

    @media (min-width: 992px) {
      height: 50px;
      font-size: 16px;
    }
  }
`;

export default Button;
