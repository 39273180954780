import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ProductVisulizationVideo from "./../assets/Videos/product-visuilization.mp4";
import PackagingVideo from "./../assets/Videos/packaging.mp4";
import ThreeDAvatarsVideo from "./../assets/Videos/3d-avatars.mp4";
import PopularArVideo from "./../assets/Videos/popular-ar-exp.mp4";
import PulseVideo from "./../assets/Videos/pulse.mp4";
import InteractiveVideo from "./../assets/Videos/interactive-print.mp4";
import MiniGame from "./../assets/Videos/minigame.mp4";
import QuizVideo from "./../assets/Videos/quiz.mp4";

const CustomSlider = () => {
  const comingItems = [
    {
      id: 1,
      title: "Product Visualization",
      desc: "Bring products to life with realistic 3D visualizations in AR.",
      imageUrl:
        "https://images.unsplash.com/photo-1618886487325-f665032b6352?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1064&q=80",
      videoUrl: ProductVisulizationVideo,
    },
    {
      id: 2,
      title: "Packaging",
      desc: "Elevate product packaging with immersive AR experiences.",
      imageUrl:
        "https://plus.unsplash.com/premium_photo-1674929042069-4999154c68b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2058&q=80",
      videoUrl: PackagingVideo,
    },
    {
      id: 3,
      title: "3D Avatars",
      desc: "Step into a virtual world with customizable 3D avatars.",
      imageUrl:
        "https://images.unsplash.com/photo-1628819115616-6a6b57361724?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80",
      videoUrl: ThreeDAvatarsVideo,
    },
    {
      id: 4,
      title: "3D Objects",
      desc: "Immersive worlds with 3D objects.",
      imageUrl:
        "https://images.unsplash.com/photo-1618886487325-f665032b6352?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1064&q=80",
      videoUrl: PopularArVideo,
    },
    {
      id: 5,
      title: "Filters & Effects",
      desc: "Transform reality with AR filters and effects.",
      imageUrl:
        "https://plus.unsplash.com/premium_photo-1674929042069-4999154c68b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2058&q=80",
      videoUrl: PulseVideo,
    },
    {
      id: 6,
      title: "Interactive Prints",
      desc: "Elevate product packaging with immersive AR experiences.",
      imageUrl:
        "https://images.unsplash.com/photo-1628819115616-6a6b57361724?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80",
      videoUrl: InteractiveVideo,
    },
    {
      id: 7,
      title: "Mini Games",
      desc: "Gamify your brand with immersive AR minigames.",
      imageUrl:
        "https://images.unsplash.com/photo-1628819115616-6a6b57361724?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80",
      videoUrl: MiniGame,
    },
    {
      id: 8,
      title: "Quiz",
      desc: "Engage and gather insights with interactive AR quizzes and surveys.",
      imageUrl:
        "https://images.unsplash.com/photo-1628819115616-6a6b57361724?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80",
      videoUrl: QuizVideo,
    },
  ];

  const SlickPrevArrow = ({ className, style, onClick }) => {
    return (
      <PrevBtnStyles
        className={className}
        style={{
          ...style,
          zIndex: 1,
        }}
        onClick={onClick}
      >
        <AiOutlineArrowLeft />
      </PrevBtnStyles>
    );
  };

  const SlickNextArrow = ({ className, style, onClick }) => {
    return (
      <NextBtnStyles
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        <AiOutlineArrowRight />
      </NextBtnStyles>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper
      className="paddingTopDesktop-fifty paddingBottomDesktop-eighty paddingTopMobile-fifty paddingBottomMobile-eighty"
      id="useCases"
    >
      <div className="wrapper">
        <div className="slider-title-container paddingBottomDesktop-fifty paddingBottomMobile-fifty">
          <div className="slider-small-heading">
            <p className="special-text-color ">Use Cases</p>
          </div>
          <div className="slider-heading">
            <p>Templates for anything</p>
          </div>
        </div>
        <Slider {...settings}>
          {comingItems.map((eachItem) => {
            return (
              <article key={eachItem.id} className="each-slider">
                <div className="article-content">
                  <div className="article-title text-center">
                    <p>{eachItem.title}</p>
                  </div>
                  <div className="article-desc text-center">
                    <p>{eachItem.desc}</p>
                  </div>
                  <div className="article-img-container">
                    {eachItem.videoUrl ? (
                      <video
                        className="video"
                        width={"100%"}
                        src={eachItem.videoUrl}
                        autoPlay
                        loop
                        muted
                      ></video>
                    ) : (
                      <img src={eachItem.imageUrl} alt="" />
                    )}
                  </div>
                </div>
              </article>
            );
          })}
        </Slider>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .slider-title-container {
    .slider-small-heading {
      font-size: 18px;
      line-height: 26px;
      font-weight: var(--font-weight-500);
      /* color: var(--clr-primary); */
    }
    .slider-heading {
      font-size: 34px;
      line-height: 48px;
      font-weight: var(--font-weight-500);
    }

    @media (min-width: 992px) {
      .slider-heading {
        font-size: 44px;
        line-height: 62px;
      }
    }
  }

  .slick-list {
    margin: 0 -15px;
  }
  .slick-slide > div {
    padding: 0 15px;
  }
  .slick-next {
    right: 10px;
  }
  .slick-prev {
    left: 10px;
  }
  @media (min-width: 992px) {
    .slick-next {
      right: 15px;
    }
    .slick-prev {
      left: 15px;
    }
  }

  /* custom each-slider */
  .each-slider {
    width: 100%;
    height: 100%;
    height: 500px;
    border-radius: 40px;
    overflow: hidden;
    background-color: var(--clr-grey);
    padding: 25px 20px;
    padding-bottom: 0px;
    box-shadow: var(--box-shadow-light);
    transition: var(--transition);

    &:hover {
      box-shadow: var(--box-shadow-dark);
    }

    .article-title p {
      font-size: 22px;
      line-height: 30px;
      font-weight: var(--font-weight-500);
      margin-bottom: 12px;
    }

    .article-desc p {
      font-size: 14px;
      line-height: 20px;
      color: var(--clr-grey-text);
      margin-bottom: 30px;
    }

    .article-content {
      width: 100%;
      height: 100%;
    }

    .article-img-container {
      width: 80%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      background: var(--clr-black);
      border-radius: 35px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
      }
      .video {
        border-radius: 30px;
      }
    }

    @media (min-width: 992px) {
      height: 600px;
      border-radius: 90px;

      .article-title p {
        font-size: 24px;
        line-height: 34px;
      }

      .article-desc p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 40px;
      }
    }
  }

  /* slick dots */
  .slick-dots {
    bottom: -35px;

    > li {
      width: 15px;
      height: 15px;
      border: 2px solid var(--clr-primary);
      border-radius: 50%;
      padding: 1px;
    }
    li button {
      width: 100%;
      height: 100%;
      border: none;
      padding: 0px;
      outline: none;
      border-radius: 50%;
    }
    li button:focus {
      outline: 4px solid var(--clr-primary);
      offset: 2px;
    }
    li.slick-active button {
      background: var(--clr-primary);
    }
    button:before {
      display: none;
    }

    @media (min-width: 992px) {
      bottom: -55px;

      > li {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 2px;
      }
    }
  }
`;

const PrevBtnStyles = styled.button`
  width: 30px;
  height: 30px;
  background: var(--clr-primary);
  /* background: var(--clr-white); */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    background: var(--clr-primary);
  }
  /* &:focus {
    outline: 4px solid var(--clr-primary);
    offset: 2px;
  } */

  /* &:not(.slick-disabled):active {
    background: var(--clr-grey-text);
    background: var(--clr-grey-text);
  } */
  /* &:not(.slick-disabled):active svg {
    color: var(--clr-white);
  } */

  &.slick-disabled {
    opacity: 40%;
    cursor: not-allowed;
  }

  svg {
    font-size: 20px;
    color: var(--clr-white);
    /* color: var(--clr-primary); */
  }

  &::before {
    display: none;
  }
`;

const NextBtnStyles = styled.button`
  width: 30px;
  height: 30px;
  background: var(--clr-primary);
  /* background: var(--clr-white); */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    background: var(--clr-primary);
  }
  /* &:focus {
    outline: 4px solid var(--clr-primary);
    offset: 2px;
  } */

  /* &:not(.slick-disabled):active {
    background: var(--clr-grey-text);
  } */
  /* &:not(.slick-disabled):active svg {
    color: var(--clr-white);
  } */

  &.slick-disabled {
    opacity: 40%;
    cursor: not-allowed;
  }

  svg {
    font-size: 20px;
    color: var(--clr-white);
    /* color: var(--clr-primary); */
  }

  &::before {
    display: none;
  }
`;

export default CustomSlider;
