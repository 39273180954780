import React from "react";
import styled from "styled-components";
import BenfitCustomer from "./../assets/benfit-customer.svg";
import BenfitBoost from "./../assets/benfit-boost.svg";
import BenfitCost from "./../assets/benfit-cost.svg";
import BenfitGain from "./../assets/benfit-gain.svg";
import BenfitIncrease from "./../assets/benfit-increase.svg";

function Benefits() {
  return (
    <Wrapper className="benefits" id="benefits">
      <div className="benefits-center wrapper paddingTopDesktop-fifty paddingTopMobile-twenty paddingBottomDesktop-fifty paddingBottomMobile-twenty">
        <div className="benefits-title-container">
          <p className="benefits-title special-text-color">Benefits</p>
          <p className="benefits-heading">
            Embracing
            <span className="special-text-color display-block">
              The Future of Marketing
            </span>
          </p>
        </div>
        <div className="benefits-list paddingTopDesktop-fifty paddingTopMobile-thirty">
          <article className="each-benefit">
            <div className="benefit-img-container">
              <div className="benefit-img">
                <img src={BenfitCustomer} alt="People" />
              </div>
            </div>
            <p className="each-benefit-title">Customer Engagement</p>
            <p className="each-benefit-desc">
              Immerse your audience with interactive AR experiences that enhance
              customer engagement like never before.
            </p>
          </article>
          <article className="each-benefit">
            <div className="benefit-img-container">
              <div className="benefit-img">
                <img src={BenfitIncrease} alt="Benfit Increase" />
              </div>
            </div>
            <p className="each-benefit-title">Increase Brand Awareness</p>
            <p className="each-benefit-desc">
              Unlock the power of augmented reality to create captivating brand
              experiences and elevate your brand awareness to new heights.
            </p>
          </article>
          <article className="each-benefit">
            <div className="benefit-img-container">
              <div className="benefit-img">
                <img src={BenfitBoost} alt="Benfit Boost" />
              </div>
            </div>
            <p className="each-benefit-title">Boost Sales and Revenue</p>
            <p className="each-benefit-desc">
              Drive sales growth and boost your revenue with Stegofy AR's
              immersive marketing solutions that captivate and convert customers
            </p>
          </article>
          <article className="each-benefit">
            <div className="benefit-img-container">
              <div className="benefit-img">
                <img src={BenfitGain} alt="Benfit Gain" />
              </div>
            </div>
            <p className="each-benefit-title">Gain Valuable Insights</p>
            <p className="each-benefit-desc">
              Unlock valuable insights into consumer behavior and campaign
              performance with comprehensive metrics analysis powered by AR.
            </p>
          </article>
          <article className="each-benefit">
            <div className="benefit-img-container">
              <div className="benefit-img">
                <img src={BenfitCost} alt="Benfit Cost" />
              </div>
            </div>
            <p className="each-benefit-title">Cost-Effective Solutions</p>
            <p className="each-benefit-desc">
              Discover cost-effective marketing solutions that deliver
              exceptional results and maximize your ROI with Stegofy AR's
              affordable augmented reality platform.
            </p>
          </article>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .benefits-title {
    font-size: 18px;
    line-height: 26px;
    font-weight: var(--font-weight-500);
    /* color: var(--clr-primary); */
  }
  .benefits-heading {
    font-size: 34px;
    line-height: 48px;
    font-weight: var(--font-weight-500);

    /* .special {
      color: var(--clr-primary);
    } */
  }

  .benefits-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .each-benefit {
    width: 100%;
    padding: 90px 10px 20px 10px;
    border-radius: 30px;
    background: var(--clr-grey);
    position: relative;
    overflow: hidden;

    .each-benefit-title {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 10px;
      font-family: 500;
    }

    .each-benefit-desc {
      font-size: 16px;
      line-height: 22px;
      color: var(--clr-grey-text);
    }
  }
  .benefit-img-container {
    position: absolute;
    top: 10px;
    left: 10px;

    .benefit-img {
      position: relative;
      padding: 10px;
      border-radius: 50%;
      border: 1px solid var(--clr-primary) 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        180deg,
        var(--clr-blue-light),
        var(--clr-blue-dark)
      );

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 30px);
        height: calc(100% + 30px);
        border: 1px solid var(--clr-primary);
        border-radius: 50%;
        opacity: 30%;
        /* border: 1px solid linear-gradient(180deg, #007bfe 0%, #53a6ff 100%); */
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 60px);
        height: calc(100% + 60px);
        border: 1px solid var(--clr-primary);
        border-radius: 50%;
        opacity: 10%;
        /* border: 1px solid linear-gradient(180deg, #007bfe 0%, #53a6ff 100%); */
      }
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  @media (min-width: 992px) {
    .benefits-heading {
      font-size: 44px;
      line-height: 62px;
    }

    .benefits-list {
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      gap: 20px;
    }

    .each-benefit {
      padding: 115px 20px 30px 20px;
      border-radius: 50px;

      .each-benefit-title {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 15px;
      }
    }

    .benefit-img-container {
      top: 15px;
      left: 15px;

      .benefit-img {
        padding: 15px;

        &::before {
          width: calc(100% + 40px);
          height: calc(100% + 40px);
          /* border: 1px solid linear-gradient(180deg, #007bfe 0%, #53a6ff 100%); */
        }
        &::after {
          width: calc(100% + 80px);
          height: calc(100% + 80px);
          /* border: 1px solid linear-gradient(180deg, #007bfe 0%, #53a6ff 100%); */
        }
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

export default Benefits;
