import React, { useEffect } from "react";
import {
  GetStarted,
  OurPartners,
  Benefits,
  GetStartedHero,
  FeaturesHero,
  ContactUs,
  LeadingHero,
  PercentageHero,
  CustomSlider,
} from "./../components";
import { links, navigateToSpecificComponent } from "./../utils";

function Home() {
  useEffect(() => {
    let requiredPath = window.location.pathname.replace("/", "");
    if (!requiredPath) return;

    const linksPath = links.map((eachLink) => eachLink.path);
    if (!linksPath.includes(requiredPath)) return;

    requiredPath = "#" + requiredPath;
    const timerSet = setTimeout(() => {
      navigateToSpecificComponent("", requiredPath, 0);
    }, 300);

    return () => {
      if (timerSet) window.removeEventListener(timerSet);
    };
  }, []);

  return (
    <div className="paddingTopMobile-ten paddingTopDesktop-twenty">
      <GetStarted />
      {/* <OurPartners /> */}
      <Benefits />
      <PercentageHero />
      <LeadingHero />
      <GetStartedHero />
      <CustomSlider />
      <FeaturesHero />
      <ContactUs />
    </div>
  );
}

export default Home;
