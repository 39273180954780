import React from "react";
import styled from "styled-components";

function Input({
  labelName,
  placeHolderText,
  type = "text",
  customStyles = {},
  mode = "primary",
  handleChange,
  value,
  textarea = false,
  id,
  name,
}) {
  return (
    <Wrapper className="input-center">
      <label className={`input-text ${mode}`} htmlFor={labelName}>
        {labelName}
      </label>
      {!textarea ? (
        <input
          type={type}
          className={`primary-input ${mode}`}
          value={value}
          // id={labelName}
          onChange={(e) => handleChange(e)}
          placeholder={placeHolderText}
          style={{ ...customStyles }}
          id={id}
          name={name}
        />
      ) : (
        <textarea
          className={`primary-input text-area ${mode}`}
          value={value}
          // id={labelName}
          onChange={(e) => handleChange(e)}
          placeholder={placeHolderText}
          style={{ ...customStyles }}
          id={id}
          name={name}
        ></textarea>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .input-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--clr-white);

    &.secundary {
      color: var(--clr-black);
    }
  }
  .primary-input {
    height: 45px;
    font-size: 14px;
    line-height: 20px;
    color: var(--clr-black);
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 14px;
    background: var(--clr-white);
    border: none;

    &:focus {
      outline: 1px solid var(--clr-primary);
    }

    &.secundary {
      color: var(--clr-black);
      background: var(--clr-blue-dark);
    }
  }
  .text-area {
    font-family: var(--font-family);
    padding: 15px;
    font-size: 14px;
    line-height: 20px;
    min-height: 150px;
    resize: vertical;
  }

  @media (min-width: 992px) {
    .input-text {
      font-size: 16px;
      line-height: 22px;
    }
    .primary-input {
      height: 50px;
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

export default Input;
