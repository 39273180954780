export const links = [
  {
    id: 1,
    title: "About Us",
    path: "about",
  },
  {
    id: 2,
    title: "Benefits",
    path: "benefits",
  },
  {
    id: 3,
    title: "Use Cases",
    path: "useCases",
  },
  {
    id: 4,
    title: "Features",
    path: "features",
  },
  {
    id: 5,
    title: "Contact Us",
    path: "contactus",
  },
];

export const navigateToTop = (checkBehaviour = "smooth") => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: checkBehaviour,
  });
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export function validatePhoneNumber(phoneNumber) {
  const regex = /^(?:\+?91|0)?[6-9]\d{9}$/;
  return regex.test(phoneNumber);
}

export function navigateToSpecificComponent(e, excactItem, customHeight) {
  let requiredItem;
  if (excactItem) {
    requiredItem = document.querySelector(excactItem);
    if (requiredItem === null || requiredItem === undefined) {
      window.location.href = window.origin;
    }
  } else {
    requiredItem = document.querySelector(
      "#" + e.currentTarget.pathname.replace("/", "")
    );
    // if (requiredItem === null || requiredItem === undefined) {
    //   window.location.href = window.origin;
    // }
  }
  window.scrollTo({
    top: requiredItem?.offsetTop - (customHeight ? customHeight : 70),
    left: 0,
    behavior: "smooth",
  });
}
