import React, { useState } from "react";
import styled from "styled-components";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function Select({
  labelName,
  placeHolderText,
  dropdownList = [],
  optionName,
  optionValue,
  handleChange,
}) {
  const [text, setText] = useState("");

  // const handleChange = (e) => {
  //   setText(e.target.value);
  // };

  return (
    <Wrapper className="select-center">
      <label className="select-text" htmlFor="labelName">
        {labelName}
      </label>
      <div className="select-main">
        <select
          className="primary-select"
          onChange={handleChange}
          defaultValue={placeHolderText}
        >
          <option disabled value={placeHolderText} className="default-value">
            {placeHolderText}
          </option>
          {dropdownList.map((eachItem) => {
            return (
              <option
                key={eachItem.id}
                title={eachItem[optionName]}
                value={eachItem[optionValue]}
              >
                {eachItem[optionName]}
              </option>
            );
          })}
        </select>
        <div className="icon">
          <MdOutlineKeyboardArrowDown />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  .select-main {
    position: relative;

    .icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      pointer-events: none;

      svg {
        font-size: 20px;
      }
    }
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }

  .select-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--clr-white);
  }
  .primary-select {
    width: 100%;
    height: 45px;
    font-size: 14px;
    line-height: 20px;
    color: var(--clr-black);
    padding-left: 15px;
    padding-right: 40px;
    border-radius: 14px;
    background: var(--clr-white);
    border: none;

    &:focus {
      outline: 1px solid var(--clr-primary);
    }
  }

  @media (min-width: 992px) {
    .select-text {
      font-size: 16px;
      line-height: 22px;
    }
    .primary-select {
      height: 50px;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .select-main {
    .icon svg {
      font-size: 26px;
    }
  }
`;

export default Select;
