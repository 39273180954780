import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "./../assets/logo.svg";
import Instagram from "./../assets/instagram.svg";
import Twitter from "./../assets/Twitter.svg";
import FaceBook from "./../assets/facebook.svg";
import Linkedin from "./../assets/linkedin.svg";
import { Button, Input } from "./../atoms";
import { Link } from "react-router-dom";
import {
  navigateToSpecificComponent,
  navigateToTop,
  validateEmail,
} from "../utils";

function Footer() {
  const [userEmail, setUserEmail] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [currOrigin, setCurrOrigin] = useState("");

  useEffect(() => {
    setCurrOrigin(window.origin);
  }, []);

  useEffect(() => {
    if (!userEmail) {
      setDisableSubmit(true);
      return;
    }

    if (!(userEmail && validateEmail(userEmail))) {
      setDisableSubmit(true);
      return;
    }

    setDisableSubmit(false);
  }, [userEmail]);

  const submitUserEmail = (e) => {
    e.preventDefault();
    console.log("user email", userEmail);
  };

  return (
    <Wrapper className="footer">
      <div className="footer-center wrapper">
        <div className="footer-content paddingTopDesktop-fifty paddingBottomDesktop-fifty paddingTopMobile-twenty paddingBottomMobile-twenty">
          <div className="footer-logo-social">
            <Link
              to="/"
              className="footer-logo"
              onClick={() => navigateToTop()}
            >
              <img src={Logo} alt="logo" />
            </Link>
            <div className="footer-social-icons">
              <ul className="social-icons">
                <li>
                  <a
                    href="https://www.instagram.com/stegofy/"
                    className="social-icon"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Instagram} alt="" />
                  </a>
                </li>
                <li>
                  <a href="_" className="social-icon">
                    <img src={Twitter} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/stegofy/"
                    className="social-icon"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a href="_" className="social-icon">
                    <img src={FaceBook} alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <form
              action="https://submit-form.com/X50RUrkk"
              className="footer-form"
            >
              <input
                type="hidden"
                name="_redirect"
                value={`${currOrigin}/thankyou`}
              />
              <div className="input-container">
                <label
                  className="label-heading secundary"
                  htmlFor="email-footer"
                >
                  Subscribe to our Newsletter
                </label>
                <input
                  type="email"
                  id="email-footer"
                  name="email"
                  className="primary-input secundary"
                  placeholder="Enter your Email"
                  required
                />
              </div>
              <div className="form-button-footer">
                <button type="submit" className="primary-button">
                  Submit
                </button>
              </div>
            </form>
            {/* <div className="input-container">
              <Input
                labelName={"Subscribe our newletter"}
                type="email"
                mode="secundary"
                placeHolderText={"Enter your Email"}
                value={userEmail}
                handleChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="button-container">
              <Button
                title="submit"
                handleChange={submitUserEmail}
                disabled={disableSubmit}
              />
            </div> */}
          </div>
          <div className="footer-links">
            <div className="each-section">
              <div className="each-section-heading">
                <p>Solutions</p>
              </div>
              <ul className="each-section-links">
                <li className="each-section-link">
                  <Link to="/useCases" onClick={navigateToSpecificComponent}>
                    Packaging
                  </Link>
                </li>
                <li className="each-section-link">
                  <Link to="/useCases" onClick={navigateToSpecificComponent}>
                    Product Visualisation
                  </Link>
                </li>
                <li className="each-section-link">
                  <Link to="/useCases" onClick={navigateToSpecificComponent}>
                    Quizzes and Surveys
                  </Link>
                </li>
                <li className="each-section-link">
                  <Link to="/useCases" onClick={navigateToSpecificComponent}>
                    3D Avatars
                  </Link>
                </li>
                <li className="each-section-link">
                  <Link to="/useCases" onClick={navigateToSpecificComponent}>
                    Minigames
                  </Link>
                </li>
                <li className="each-section-link">
                  <Link to="/useCases" onClick={navigateToSpecificComponent}>
                    Virtual Try-on
                  </Link>
                </li>
                <li className="each-section-link">
                  <Link to="/useCases" onClick={navigateToSpecificComponent}>
                    Filters & Effects
                  </Link>
                </li>
              </ul>
            </div>
            <div className="each-section">
              <div className="each-section-heading">
                <p>Industries</p>
              </div>
              <ul className="each-section-links">
                <li className="each-section-link">
                  <a href="_">Retail and e-commerce</a>
                </li>
                <li className="each-section-link">
                  <a href="_">Consumer Goods</a>
                </li>
                <li className="each-section-link">
                  <a href="_">Electronics</a>
                </li>
                <li className="each-section-link">
                  <a href="_">Automotive</a>
                </li>
                <li className="each-section-link">
                  <a href="_">Real Estate</a>
                </li>
                <li className="each-section-link">
                  <a href="_">Education</a>
                </li>
              </ul>
            </div>
            <div className="each-section">
              <div className="each-section-heading">
                <p>Company</p>
              </div>
              <ul className="each-section-links">
                <li className="each-section-link">
                  <Link to="/about" onClick={navigateToSpecificComponent}>
                    About Us
                  </Link>
                </li>
                <li className="each-section-link">
                  <a href="_">Blog</a>
                </li>
                <li className="each-section-link">
                  <a href="_">Privacy Policy</a>
                </li>
                <li className="each-section-link">
                  <a href="_">Terms & Conditions</a>
                </li>
                <li className="each-section-link">
                  <Link to="/contactus" onClick={navigateToSpecificComponent}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="each-section">
              <div className="each-section-heading">
                <p>Community</p>
              </div>
              <ul className="each-section-links">
                <li className="each-section-link">
                  <a href="_">Documentation</a>
                </li>
                <li className="each-section-link">
                  <a href="_">FAQs</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright paddingBottomDesktop-fifty paddingBottomMobile-thirty">
          <p className="copyright-text">COPYRIGHT © 2023 STEGOFY</p>
          {/* <p className="copyright-link">
            Developed by{" "}
            <a
              href="http://racharlasrikanth.netlify.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              Srikanth Racharla
            </a>
          </p> */}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  .footer-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .footer-logo-social {
    .footer-logo {
      display: block;
      width: 125px;
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }
    .footer-social-icons .social-icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      margin-bottom: 40px;

      .social-icon {
        width: 34px;
        height: 34px;
        background: var(--clr-blue-dark);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: var(--transition);
        border: 1px solid transparent;

        &:hover {
          background: var(--clr-blue-light);
          border-color: var(--clr-primary);
        }
      }
    }
  }
  .input-container {
    margin-bottom: 20px;
  }
  .footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .footer-links .each-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    .each-section-links {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;
    }
    .each-section-heading {
      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: var(--font-weight-500);
      }
    }
    .each-section-link a {
      font-size: 12px;
      line-height: 18px;
      color: var(--clr-black);
    }
  }
  .footer-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    .copyright-text {
      color: var(--clr-grey-text);
    }
    .copyright-link {
      color: var(--clr-grey-text);

      > a {
        color: var(--clr-primary);
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 992px) {
    .footer-content {
      grid-template-columns: 0.5fr 1fr;
      gap: 70px;
    }
    .footer-links {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .footer-links .each-section {
      gap: 12px;
      .each-section-links {
        gap: 7px;
      }
      .each-section-heading {
        p {
          font-size: 18px;
          line-height: 26px;
        }
      }
      .each-section-link a {
        font-size: 14px;
        line-height: 20px;
        color: var(--clr-black);
        transition: var(--transition);

        &:hover {
          color: var(--clr-primary);
        }
      }
    }
    .footer-logo-social {
      .footer-logo {
        width: 200px;
        margin-bottom: 40px;
      }
      .footer-social-icons .social-icons {
        margin-bottom: 60px;
      }
    }
    .footer-copyright {
      flex-direction: row;
    }
  }
`;

export default Footer;
