import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AiOutlineArrowUp } from "react-icons/ai";
import { navigateToTop } from "../utils";

const BackToTop = () => {
  const [stickyClass, setStickyClass] = useState("");

  function stickBackBtn() {
    let windowHeight = window.scrollY;
    windowHeight > 700 ? setStickyClass("show-back-btn") : setStickyClass("");
  }

  useEffect(() => {
    window.addEventListener("scroll", stickBackBtn);

    return () => window.removeEventListener("scroll", stickBackBtn);
  }, []);

  return (
    <Wrapper className={stickyClass}>
      <button className="back-to-top" onClick={() => navigateToTop()}>
        <AiOutlineArrowUp />
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: sticky;
  bottom: 20px;
  z-index: 1;
  opacity: 0;
  transition: var(--transition);
  display: none;

  .back-to-top {
    width: 30px;
    height: 30px;
    background: var(--clr-primary);
    color: var(--clr-white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-left: auto;
    margin-right: 20px;
    border: 2px solid var(--clr-white);
    box-shadow: var(--box-shadow-dark);
    cursor: pointer;

    svg {
      font-size: 20px;
    }
  }

  &.show-back-btn {
    opacity: 1;
    display: flex;
  }

  @media (min-width: 992px) {
    bottom: 30px;

    .back-to-top {
      width: 40px;
      height: 40px;

      svg {
        font-size: 26px;
      }
    }
  }
`;

export default BackToTop;
