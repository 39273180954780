import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "./../assets/logo.svg";
import Bars from "./../assets/bars.svg";
import { links, navigateToTop, navigateToSpecificComponent } from "../utils";
import { Link } from "react-router-dom";
import { Button } from "./../atoms";

const Navbar = ({ openSidebar }) => {
  const [stickyClass, setStickyClass] = useState("");

  function stickNavbar() {
    let windowHeight = window.scrollY;
    windowHeight > 70 ? setStickyClass("sticky-nav") : setStickyClass("");
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  return (
    <Wrapper className={`"navbar" ${stickyClass}`}>
      <div className="nav-center wrapper">
        <Link to="/" className="logo-container" onClick={() => navigateToTop()}>
          <img src={Logo} alt="logo" />
        </Link>
        <div className="nav-links">
          <ul className="nav-links-center">
            {links.map((eachLink) => {
              return (
                <li key={eachLink.id}>
                  <Link
                    className="nav-link"
                    to={eachLink.path}
                    onClick={navigateToSpecificComponent}
                  >
                    {eachLink.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="nav-btns-container">
          <div className="nav-btn">
            <Button
              title="Get Started"
              type="primary"
              handleChange={(e) => {
                navigateToSpecificComponent(e, "#contactus");
              }}
            ></Button>
          </div>
          <button className="nav-bars" onClick={() => openSidebar()}>
            <img src={Bars} alt="bars for sidebar" />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--clr-white);
  transition: var(--transition);
  top: -60px;

  &.sticky-nav {
    top: 0px;
    left: 0;
    position: sticky;
    /* height: 60px; */
    z-index: 10;
    box-shadow: var(--box-shadow-dark);
    /* transition: var(--transition); */
  }

  .nav-links {
    display: none;
  }
  .nav-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-btns-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
  .logo-container {
    width: 100px;

    img {
      width: 100%;
    }
  }
  .nav-bars {
    border: none;
    background: transparent;
  }

  @media (min-width: 992px) {
    height: 80px;
    /* &.sticky-nav {
      height: 100px;
    } */
    .nav-links {
      display: flex;
    }
    .nav-links-center {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
    .nav-links .nav-link {
      font-size: 14px;
      color: var(--clr-black);
      transition: var(--transition);

      &:hover {
        color: var(--clr-primary);
      }
    }
    .nav-btns-container .nav-bars {
      display: none;
    }
    .logo-container {
      width: 120px;
    }
  }
`;

export default Navbar;
