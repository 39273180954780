import React from "react";
import styled from "styled-components";

function PercentageHero() {
  return (
    <Wrapper className="percentage-hero">
      <div className="percentage-hero-center wrapper paddingTopDesktop-fifty paddingBottomMobile-twenty paddingBottomDesktop-fifty paddingTopMobile-twenty">
        <div className="percentage-hero-content">
          <div className="percentage-title-container">
            <p>Unleashing Potential of Augmented Reality</p>
          </div>
          <div className="percentage-circle-list">
            <article className="each-circle c1 mwh-130 dwh-175">
              <p className="number">
                200 <span className="symbol">%</span>
              </p>
              <p className="text text-center">Return on Investment (ROI)</p>
            </article>
            <article className="each-circle c2 mwh-200 dwh-260">
              <p className="number">
                40 <span className="symbol">%</span>
              </p>
              <p className="text text-center">Boost in Engagement</p>
            </article>
            <article className="each-circle c3 mwh-180 dwh-245">
              <p className="number">
                30 <span className="symbol">%</span>
              </p>
              <p className="text text-center">Increase in Sales</p>
            </article>
            <article className="each-circle c4 mwh-160 dwh-280">
              <p className="number">
                70 <span className="symbol">%</span>
              </p>
              <p className="text text-center">Brand Awareness Growth</p>
            </article>
            <article className="each-circle c5 mwh-125 dwh-185">
              <p className="number">
                25 <span className="symbol">%</span>
              </p>
              <p className="text text-center">Conversion Rate Improvement</p>
            </article>
            <article className="each-circle c6 mwh-190 dwh-215">
              <p className="number">
                60 <span className="symbol">%</span>
              </p>
              <p className="text text-center">Enhanced Brand Storytelling</p>
            </article>
            <article className="each-circle c7 mwh-125 dwh-180">
              <p className="number">
                25 <span className="symbol">%</span>
              </p>
              <p className="text text-center">Customer Satisfaction</p>
            </article>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  .percentage-hero-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .percentage-title-container {
    p {
      font-size: 34px;
      line-height: 48px;
      font-weight: var(--font-weight-500);
    }
  }
  .percentage-circle-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* place-items: center; */
    overflow: hidden;

    @media (max-width: 991px) {
      margin-bottom: -160px;
    }
  }
  .each-circle {
    /* width: 150px;
    height: 150px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0) 0%,
      var(--clr-primary-light) 240%
    );
    /* background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0) 0%,
      #53a6ff51 100%
    ); */
    position: relative;

    @media (max-width: 991px) {
      &.c1 {
        /* transform: translateX(0); */
        /* margin-top: -40px; */
      }
      &.c2 {
        transform: translateX(-50px);
      }
      &.c3 {
        transform: translateY(-90px);
        /* margin-top: -60px; */
      }
      &.c4 {
        transform: translate(-10px, -10px);
        /* margin-left: -10px; */
      }
      &.c5 {
        transform: translate(30px, -110px);
        /* margin-top: -50px; */
      }
      &.c6 {
        transform: translate(-60px, -70px);
        /* margin-left: -60px;
        margin-top: -10px; */
      }
      &.c7 {
        transform: translateY(-180px);
        /* margin-top: -110px; */
      }
    }

    .number {
      font-size: 40px;
      line-height: 26px;
      color: var(--clr-primary);
      position: relative;
      font-weight: var(--font-weight-500);

      .symbol {
        position: absolute;
        right: -20px;
        bottom: -5px;
        font-size: 16px;
      }
    }
    .text {
      width: 80%;
      font-size: 12px;
      line-height: 16px;
      font-weight: var(--font-weight-500);
    }
  }

  @media (min-width: 992px) {
    .percentage-hero-content {
      grid-template-columns: 300px 1fr;
      gap: 20px;
    }
    .percentage-title-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 40px;

      p {
        font-size: 36px;
        line-height: 52px;
      }
    }
    .percentage-circle-list {
      grid-template-columns: repeat(4, 1fr);
    }
    .each-circle {
      gap: 20px;

      .number {
        font-size: 50px;
        line-height: 36px;

        .symbol {
          right: -20px;
          bottom: -5px;
        }
      }
      .text {
        font-size: 14px;
        line-height: 18px;
      }

      &.c1 {
        transform: translateY(130px);
      }
      &.c2 {
        transform: translateX(-30px);
      }
      &.c3 {
        transform: translateX(-30px) translateY(40px);
      }
      &.c4 {
        transform: translateX(-40px) translateY(80px);
      }
      &.c5 {
        transform: translateX(100px) translateY(-10px);
      }
      &.c6 {
        transform: translateX(110px) translateY(-30px);
      }
      &.c7 {
        transform: translateX(70px);
      }
    }
  }
`;

export default PercentageHero;
