import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "./../atoms";

function NotFound() {
  return (
    <Wrapper>
      <div className="not-found-center wrapper page">
        <div className="not-found-title">
          <h1>Page Not Found</h1>
        </div>
        <div className="btn-container">
          <Link to="/">
            <Button title="back to home page" />
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  .not-found-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
`;

export default NotFound;
