import React from "react";
import styled from "styled-components";
import FeatureTemplate from "./../assets/feature-1.svg";
import FeatureCustom from "./../assets/feature-customized.svg";
import FeatureNoApp from "./../assets/feature-no-app.svg";
import FeatureNoCode from "./../assets/feature-no-code.svg";
import FeatureUser from "./../assets/feature-user.svg";
import FeatureCloud from "./../assets/feature-cloud.svg";

function FeaturesHero() {
  return (
    <Wrapper className="features" id="features">
      <div className="features-center wrapper paddingTopDesktop-fifty paddingTopMobile-twenty paddingBottomDesktop-fifty paddingBottomMobile-twenty">
        <div className="features-title-container">
          <p className="features-title special-text-color">Features</p>
          <p className="features-heading">
            Seamless Integrations
            <span className="special-text-color display-block">
              To Elevate Customer Engagement
            </span>
          </p>
        </div>

        <div className="features-list paddingTopDesktop-fifty paddingTopMobile-twenty">
          <article className="each-feature">
            <div className="img-container">
              <img src={FeatureTemplate} alt="feature" />
            </div>
            <div className="feature-desc">
              <p className="each-feature-title">Templates for all your needs</p>
              <p className="each-feature-text">
                Endless AR possibilities with customizable templates.
              </p>
            </div>
          </article>
          <article className="each-feature">
            <div className="img-container">
              <img src={FeatureCustom} alt="feature" />
            </div>
            <div className="feature-desc">
              <p className="each-feature-title">Customized URLs or QR codes</p>
              <p className="each-feature-text">
                Direct customers to AR content with custom URLs or QR codes.
              </p>
            </div>
          </article>
          <article className="each-feature">
            <div className="img-container">
              <img src={FeatureNoApp} alt="feature" />
            </div>
            <div className="feature-desc">
              <p className="each-feature-title">No App install required</p>
              <p className="each-feature-text">
                Instant AR experiences without app installation.
              </p>
            </div>
          </article>
          <article className="each-feature">
            <div className="img-container">
              <img src={FeatureNoCode} alt="feature" />
            </div>
            <div className="feature-desc">
              <p className="each-feature-title">No-Code WebAR platform</p>
              <p className="each-feature-text">
                Create AR experiences without coding expertise.
              </p>
            </div>
          </article>
          <article className="each-feature">
            <div className="img-container">
              <img src={FeatureUser} alt="feature" />
            </div>
            <div className="feature-desc">
              <p className="each-feature-title">User Analytics</p>
              <p className="each-feature-text">
                Actionable insights into user behavior.
              </p>
            </div>
          </article>
          <article className="each-feature">
            <div className="img-container">
              <img src={FeatureCloud} alt="feature" />
            </div>
            <div className="feature-desc">
              <p className="each-feature-title">Cloud-Based platforms</p>
              <p className="each-feature-text">
                Manage AR content from anywhere.
              </p>
            </div>
          </article>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .features-title {
    font-size: 18px;
    line-height: 26px;
    font-weight: var(--font-weight-500);
    /* color: var(--clr-primary); */
  }
  .features-heading {
    font-size: 34px;
    line-height: 48px;
    font-weight: var(--font-weight-500);

    /* .special {
      color: var(--clr-primary);
    } */
  }
  .features-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .each-feature {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    .img-container {
      width: 50px;
      height: 100%;
      padding-top: 5px;

      img {
        width: 100%;
      }
    }

    .each-feature-title {
      font-size: 18px;
      line-height: 22px;
      font-weight: var(--font-weight-500);
    }
    .each-feature-text {
      font-size: 14px;
      line-height: 20px;
      color: var(--clr-grey-text);
    }
    .feature-desc {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  @media (min-width: 992px) {
    .features-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
    }
    .each-feature {
      grid-template-columns: 50px 1fr;
      gap: 20px;

      .each-feature-title {
        font-size: 24px;
        line-height: 34px;
      }
      .each-feature-text {
        font-size: 16px;
        line-height: 22px;
        color: var(--clr-grey-text);
      }
    }
  }
`;

export default FeaturesHero;
