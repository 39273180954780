import React from "react";
import styled from "styled-components";
import AuchanCompany from "./../assets/auchan1.svg";
import LacosteCompany from "./../assets/lacoste-logo.svg";
import MacdonalsCompany from "./../assets/mcdonalds-7.svg";
import MercedesBenzCompany from "./../assets/mercedes-benz-8.svg";
import VolvoCompany from "./../assets/volvo-logo-2.svg";

function OurPartners() {
  // const companiesList = [
  //   {
  //     id: 1,
  //     companyLogo: "axla-company.svg",
  //   },
  //   {
  //     id: 2,
  //     companyLogo: "buildify-company.svg",
  //   },
  //   {
  //     id: 3,
  //     companyLogo: "microsoft-company.png",
  //   },
  //   {
  //     id: 4,
  //     companyLogo: "ocr-company.svg",
  //   },
  // ];

  return (
    <Wrapper className="our-partners" id="ourpartners">
      <div className="our-partners-center wrapper paddingTopDesktop-fifty paddingTopMobile-twenty paddingBottomDesktop-fifty paddingBottomMobile-twenty">
        <div className="our-partners-title text-center">
          <p>Our Partners</p>
        </div>
        <div className="our-partners-text text-center">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic,
            explicabo eius quas velit minima laudantium?
          </p>
        </div>
        <div className="companies-list paddingTopDesktop-thirty paddingTopMobile-twenty paddingBottomDesktop-thirty paddingBottomMobile-twenty">
          <div className="each-company">
            <img src={AuchanCompany} alt="company logo" />
          </div>
          <div className="each-company">
            <img src={LacosteCompany} alt="company logo" />
          </div>
          <div className="each-company">
            <img src={MacdonalsCompany} alt="company logo" />
          </div>
          <div className="each-company">
            <img src={MercedesBenzCompany} alt="company logo" />
          </div>
          <div className="each-company">
            <img src={VolvoCompany} alt="company logo" />
          </div>
          {/* {companiesList.map((eachCompany) => {
            return (
              <div key={eachCompany.id} className="each-company">
                <img
                  src={`/assets/${eachCompany.companyLogo}`}
                  alt="company logo"
                />
              </div>
            );
          })} */}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .our-partners-title {
    p {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }

  .our-partners-text {
    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
      color: var(--clr-grey-text);
    }
  }

  .companies-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .each-company {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      /* background: var(--clr-white-light); */
      padding: 20px;
      border-radius: var(--radius);

      img {
        width: 100px;
        height: 100%;
      }
    }
  }

  @media (min-width: 992px) {
    .our-partner-title {
      p {
        font-size: 24px;
        line-height: 34px;
      }
    }

    .companies-list {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .each-company {
        img {
          width: 150px;
        }
      }
    }
  }
`;

export default OurPartners;
