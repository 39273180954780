import React, { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const MyForm = () => {
  const [currOrigin, setCurrOrigin] = useState("");

  useEffect(() => {
    setCurrOrigin(window.origin);
  }, []);

  return (
    <form
      action="https://submit-form.com/hbdvbWbC"
      className="contactus-us-form-container"
    >
      <input type="hidden" name="_redirect" value={`${currOrigin}/thankyou`} />
      <div className="input-container">
        <label className="label-heading" htmlFor="name">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          className="primary-input"
          placeholder="Enter your Name"
          required
        />
      </div>
      <div className="input-container">
        <label className="label-heading" htmlFor="email">
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="primary-input"
          placeholder="Enter your Email"
          required
        />
      </div>
      <div className="side-by-side">
        <div className="input-container">
          <label className="label-heading" htmlFor="companyName">
            Company Name
          </label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            className="primary-input"
            placeholder="Enter Company name"
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="companySize" className="label-heading">
            Company Size
          </label>
          <div className="select-main-myform">
            <select
              name="companySize"
              id="companySize"
              className="primary-select"
              defaultValue="Select Company Size"
            >
              <option value="Select Company Size" disabled>
                Select Company Size
              </option>
              <option value="1 - 10">1 - 10</option>
              <option value="11 - 20">11 - 20</option>
              <option value="21 - 50">21 - 50</option>
              <option value="51 - 200">51 - 200</option>
              <option value="201 - 500">201 - 500</option>
              <option value="501 - 1000">501 - 1000</option>
              <option value="1001 - 5000">1001 - 5000</option>
              <option value="5001 - 10000">5001 - 10000</option>
              <option value="10001+">10001+</option>
            </select>
            <div className="select-icon">
              <MdOutlineKeyboardArrowDown />
            </div>
          </div>
        </div>
        {/* <div className="input-container">
          <label className="label-heading" htmlFor="companySize">
            Company Size
          </label>
          <input
            type="text"
            id="companySize"
            name="companySize"
            className="primary-input"
            placeholder="Enter Company Size"
            required
          />
        </div> */}
      </div>
      <div className="side-by-side">
        <div className="input-container">
          <label className="label-heading" htmlFor="phoneNumber">
            Phone Number
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            className="primary-input"
            placeholder="Enter Phone Number"
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="industry" className="label-heading">
            Company Size
          </label>
          <div className="select-main-myform">
            <select
              name="industry"
              id="industry"
              className="primary-select"
              defaultValue="Select Industry"
            >
              <option value="Select Industry" disabled>
                Select Industry
              </option>
              <option value="Apparel & Fashion">Apparel & Fashion</option>
              <option value="Arts & Crafts">Arts & Crafts</option>
              <option value="Automotive">Automotive</option>
              <option value="Commercial Real Estate">
                Commercial Real Estate
              </option>
              <option value="Consumer Electronics">Consumer Electronics</option>
              <option value="Cosmetics">Cosmetics</option>
              <option value="Design">Design</option>
              <option value="Education Management">Education Management</option>
              <option value="Electrical Electronic">
                Electrical Electronic
              </option>
              <option value="Hospitality">Hospitality</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Financial Services">Financial Services</option>
              <option value="Food & Beverages">Food & Beverages</option>
              <option value="Furniture">Furniture</option>
              <option value="Information Technology and Services">
                Information Technology and Services
              </option>
              <option value="Pharmaceuticals">Pharmaceuticals</option>
              <option value="Printing">Printing</option>
              <option value="Publishing">Publishing</option>
              <option value="Utilities">Utilities</option>
              <option value="Others">Others</option>
            </select>
            <div className="select-icon">
              <MdOutlineKeyboardArrowDown />
            </div>
          </div>
        </div>
        {/* <div className="input-container">
          <label className="label-heading" htmlFor="industry">
            Industry
          </label>
          <input
            type="text"
            id="industry"
            name="industry"
            className="primary-input"
            placeholder="Enter your Industry"
            required
          />
        </div> */}
      </div>
      <div className="input-container">
        <label htmlFor="reason" className="label-heading">
          Company Size
        </label>
        <div className="select-main-myform">
          <select
            name="reason"
            id="reason"
            className="primary-select"
            defaultValue="Select Reason"
          >
            <option value="Select Reason" disabled>
              Select Reason
            </option>
            <option value="General Enquiry">General Enquiry</option>
            <option value="Technical Support">Technical Support</option>
            <option value="Sales and Pricing">Sales and Pricing</option>
            <option value="Partnership Opportunities">
              Partnership Opportunities
            </option>
            <option value="AR Experience Customization">
              AR Experience Customization
            </option>
            <option value="Billing and Payments">Billing and Payments</option>
            <option value="Feedback and Suggestions">
              Feedback and Suggestions
            </option>
            <option value="Report a Bug">Report a Bug</option>
            <option value="Request a Demo">Request a Demo</option>
            <option value="Media and Press Inquiries">
              Media and Press Inquiries
            </option>
            <option value="Collaboration and Sponsorship">
              Collaboration and Sponsorship
            </option>
            <option value="Account Assistance">Account Assistance</option>
            <option value="Other (Please Specify)">
              Other (Please Specify)
            </option>
          </select>
          <div className="select-icon">
            <MdOutlineKeyboardArrowDown />
          </div>
        </div>
      </div>
      {/* <div className="input-container">
        <label className="label-heading" htmlFor="reason">
          Reason for Contact
        </label>
        <input
          type="text"
          id="reason"
          name="reason"
          className="primary-input"
          placeholder="Enter your Reason"
          required
        />
      </div> */}
      <div className="input-container">
        <label className="label-heading" htmlFor="message">
          How can we help you?
        </label>
        <textarea
          id="message"
          name="message"
          className="primary-textarea"
          placeholder="Some message..."
          required
          defaultValue={""}
        />
      </div>
      <div className="form-button-container">
        <button type="submit" className="primary-button secundary">
          Get Started
        </button>
      </div>
    </form>
  );
};

export default MyForm;
