import React from "react";
import styled from "styled-components";
import { Button } from "../atoms";
import IconButton from "../atoms/IconButton";
import { AiOutlinePlayCircle } from "react-icons/ai";
import ArHomeImage from "./../assets/ar-home-img.jpg";
import IphoneImage from "./../assets/iPhone.svg";
import { navigateToSpecificComponent } from "../utils";

const GetStarted = () => {
  return (
    <Wrapper className="get-started">
      <div className="get-started-center wrapper paddingTopDesktop-hundred paddingTopMobile-fifty paddingBottomMobile-twenty paddingBottomDesktop-fifty">
        <div className="ar-heading">
          <div className="main-heading paddingBottomDesktop-forty paddingBottomMobile-twenty text-center">
            <h1>
              Revolutionize your marketing
              <div className="special-text-color">with Stegofy AR</div>
            </h1>
          </div>
          <div className="btn-container">
            <Button
              title="Get Started"
              type="primary"
              handleChange={(e) => {
                navigateToSpecificComponent(e, "#contactus");
              }}
              showBotomInfo="Free - Limited Time Only"
              infoType="danger"
            ></Button>
            <IconButton
              title="See demo"
              type="secundary"
              icon={<AiOutlinePlayCircle />}
              handleChange={(e) => {
                navigateToSpecificComponent(e, ".slick-slider", 100);
              }}
            ></IconButton>
          </div>
        </div>
        <div className="ar-mobile paddingTopDesktop-hundred paddingTopMobile-fifty">
          <div className="ar-mobile-img-center">
            <div className="ar-mobile-img-container">
              <img src={ArHomeImage} alt="" />

              <div className="ar-phone-title">
                <p>
                  Transforming Customer Experiences and Fueling Business Growth
                </p>
              </div>

              <div className="phone-img-container">
                <img src={IphoneImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .main-heading {
    h1,
    h2,
    h3,
    h4 {
      font-size: 40px;
      line-height: 56px;
    }

    /* .special {
      color: var(--clr-primary);
    } */
  }
  .btn-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 10px;
  }
  .ar-mobile-img-container {
    width: 100%;
    height: 500px;
    overflow: hidden;
    border-radius: 40px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--clr-black-70);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .phone-img-container {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;

    img {
      width: 100%;
    }
  }

  .ar-phone-title {
    width: 80%;
    position: absolute;
    top: 50px;
    left: 30px;
    /* transform: translate(-50%, -50%); */

    p {
      font-size: 24px;
      line-height: 30px;
      color: var(--clr-white);
    }
  }

  @media (min-width: 992px) {
    .main-heading {
      h1,
      h2,
      h3,
      h4 {
        font-size: 54px;
        line-height: 74px;
      }
    }

    .ar-mobile-img-container {
      border-radius: 80px;
    }

    .phone-img-container {
      top: 70%;
      left: 70%;
      transform: translate(-50%, -50%);
      width: 430px;

      img {
        width: 100%;
      }
    }

    .ar-phone-title {
      width: 450px;
      position: absolute;
      top: calc(100% - 170px);
      left: 70px;

      p {
        font-size: 36px;
        line-height: 46px;
      }
    }
  }
`;

export default GetStarted;
