import React from "react";
import { Home, Thankyou } from "./../pages";
import { BackToTop, ContactUs, NotFound } from "./../components";
import { Routes, Route } from "react-router-dom";
import { Footer, Navbar, Sidebar } from "../components";

function AppRoutes({ isSidebarOpen, openSidebar, closeSidebar }) {
  return (
    <>
      <Navbar openSidebar={openSidebar} isSidebarOpen={isSidebarOpen} />
      <Sidebar isSidebarOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/" element={null}></Route>
          <Route path="about" element={null}></Route>
          <Route path="benefits" element={null}></Route>
          <Route path="useCases" element={null}></Route>
          <Route path="features" element={null}></Route>
          <Route path="contactus" element={null}></Route>
        </Route>
        <Route path="/thankyou" element={<Thankyou />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <Footer />
      <BackToTop />
    </>
  );
}

export default AppRoutes;
