import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Input, Select } from "../atoms";
import { validateEmail, validatePhoneNumber } from "./../utils";
import ContactUsWaterMark from "./../assets/contactus-logo.svg";
import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import MyForm from "./MyForm";

function ContactUs() {
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    companyName: "",
    companySize: "",
    phoneNumber: "",
    industry: "",
    reasonForContact: "",
    howCanIHelpYou: "",
    isAgreed: false,
  });
  // const [allValidationErrors, setAllValidationErrors] = useState({
  //   emailValidation: true,
  //   phoneNumberValidation: true,
  // });

  const [disbleGetStarted, setDisableGetStarted] = useState(false);

  useEffect(() => {
    if (
      !userDetails["fullName"] ||
      !userDetails["email"] ||
      !userDetails["companyName"] ||
      !userDetails["companySize"] ||
      !userDetails["phoneNumber"] ||
      !userDetails["industry"] ||
      !userDetails["reasonForContact"] ||
      !userDetails["howCanIHelpYou"] ||
      !userDetails["isAgreed"]
    ) {
      setDisableGetStarted(true);
      return;
    }

    if (
      !(userDetails["email"] && validateEmail(userDetails["email"])) ||
      !(
        userDetails["phoneNumber"] &&
        validatePhoneNumber(userDetails["phoneNumber"])
      )
    ) {
      setDisableGetStarted(true);
      return;
    }

    setDisableGetStarted(false);
  }, [userDetails]);

  const companySize = [
    {
      id: 1,
      name: "One to Ten (1 - 10)",
      value: "1-10",
    },
    {
      id: 2,
      name: "One to Hundred (1 - 100)",
      value: "1-100",
    },
    {
      id: 3,
      name: "Hundred to Five Hundred (100 - 500)",
      value: "100-500",
    },
    {
      id: 4,
      name: "Five Hundred to Thousand (500 - 1000)",
      value: "500-1000",
    },
    {
      id: 5,
      name: "More than 1000 ( > 1000)",
      value: ">1000",
    },
  ];

  const reasonsForContact = [
    {
      id: 1,
      reasonName: "Regarding Scanner",
      reasonValue: "Regarding Scanner",
    },
    {
      id: 2,
      reasonName: "Collabation for App",
      reasonValue: "Collabation for App",
    },
    {
      id: 3,
      reasonName: "Others",
      reasonValue: "Others",
    },
  ];

  const industries = [
    {
      id: 1,
      industyName: "First Company",
      industyValue: "First Company",
    },
    {
      id: 2,
      industyName: "Second Company",
      industyValue: "Second Company",
    },
    {
      id: 3,
      industyName: "Third Company",
      industyValue: "Third Company",
    },
    {
      id: 4,
      industyName: "Fourth Company",
      industyValue: "Fourth Company",
    },
  ];

  const changeInput = (newValue, fieldName) => {
    setUserDetails({
      ...userDetails,
      [fieldName]: newValue,
    });
  };

  const submitData = (e) => {
    e.preventDefault();
    console.log("user details", userDetails);
  };

  return (
    <Wrapper className="contact-us" id="contactus">
      <div className="contact-us-center paddingTopDesktop-fifty paddingBottomDesktop-fifty paddingTopMobile-twenty paddingBottomMobile-twenty wrapper">
        <div className="main-contact-us paddingTopDesktop-eighty paddingBottomDesktop-eighty paddingBottomMobile-thirty paddingTopMobile-thirty">
          <div className="left-side">
            <div className="contactus-title-container">
              <p>Book your custom free demo now</p>
            </div>
            <div className="call-note">
              <p>
                Give us a call or drop an email anytime, we endeavour to answer
                all enquiries within 24 hours on bussiness days.
              </p>
            </div>
            <div className="call-email-btns">
              <div className="call-btn">
                <div className="call-icon">
                  <IoIosCall />
                </div>
                <p className="call-text">
                  <a href="tel:+919959584060">+91 9959584060</a>
                </p>
              </div>
              <div className="email-btn">
                <div className="email-icon">
                  <MdEmail />
                </div>
                <p className="email-text">
                  <a href="mailto:soma@stegofy.com">soma@stegofy.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="form-container">
            {/* <form className="form">
              <div className="input-container">
                <Input
                  labelName={"Full Name"}
                  placeHolderText={"Enter your Name"}
                  value={userDetails["fullName"]}
                  handleChange={(e) => changeInput(e.target.value, "fullName")}
                />
              </div>
              <div className="input-container">
                <Input
                  labelName={"Company Email"}
                  placeHolderText={"Enter your Email"}
                  type="email"
                  value={userDetails["email"]}
                  handleChange={(e) => changeInput(e.target.value, "email")}
                />
              </div>
              <div className="side-by-side">
                <div className="input-container">
                  <Input
                    labelName={"Company Name"}
                    placeHolderText={"Enter your Company Name"}
                    value={userDetails["companyName"]}
                    handleChange={(e) =>
                      changeInput(e.target.value, "companyName")
                    }
                  />
                </div>
                <div className="select-container">
                  <Select
                    labelName={"Company Size"}
                    placeHolderText={"Company Size"}
                    dropdownList={companySize}
                    optionName={"name"}
                    optionValue={"value"}
                    value={userDetails["companySize"]}
                    handleChange={(e) =>
                      changeInput(e.target.value, "companySize")
                    }
                  />
                </div>
              </div>
              <div className="side-by-side">
                <div className="input-container">
                  <Input
                    labelName={"Phone Number"}
                    placeHolderText={"Enter your Phone Number"}
                    type="text"
                    value={userDetails["phoneNumber"]}
                    handleChange={(e) =>
                      changeInput(e.target.value, "phoneNumber")
                    }
                  />
                </div>
                <div className="select-container">
                  <Select
                    labelName={"Industry"}
                    placeHolderText={"Enter your Industry"}
                    dropdownList={industries}
                    optionName={"industyName"}
                    optionValue={"industyValue"}
                    value={userDetails["industry"]}
                    handleChange={(e) =>
                      changeInput(e.target.value, "industry")
                    }
                  />
                </div>
              </div>
              <div className="select-container">
                <Select
                  labelName={"Reason for Contact"}
                  placeHolderText={"Select Reason"}
                  dropdownList={reasonsForContact}
                  optionName={"reasonName"}
                  optionValue={"reasonValue"}
                  value={userDetails["reasonForContact"]}
                  handleChange={(e) =>
                    changeInput(e.target.value, "reasonForContact")
                  }
                />
              </div>
              <div className="input-container">
                <Input
                  labelName={"How can I help you?"}
                  placeHolderText={"Some message..."}
                  value={userDetails["howCanIHelpYou"]}
                  handleChange={(e) =>
                    changeInput(e.target.value, "howCanIHelpYou")
                  }
                  textarea={true}
                />
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  className="primary-checkbox agree-checkbox"
                  name="agree"
                  id="agree"
                  value={userDetails["isAgreed"]}
                  checked={userDetails["isAgreed"]}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      isAgreed: !userDetails["isAgreed"],
                    })
                  }
                />
                <label htmlFor="agree" className="agree-text">
                  I read and agree with the{" "}
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>{" "}
                  &{" "}
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    Terms of Use
                  </a>
                </label>
              </div>
              <div className="button-container">
                <Button
                  title={"get started"}
                  type="secundary"
                  disabled={disbleGetStarted}
                  handleChange={submitData}
                />
              </div>
            </form> */}
            <MyForm />
          </div>
          <div className="contact-us-watermark">
            <img src={ContactUsWaterMark} alt="water mark for contact us" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .main-contact-us {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    background: var(--clr-primary-light);
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .left-side {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .contactus-title-container {
    max-width: 500px;
    p {
      font-size: 34px;
      line-height: 48px;
      font-weight: var(--font-weight-500);
      color: var(--clr-white);
    }
  }
  .call-note {
    p {
      font-size: 16px;
      line-height: 20px;
      color: var(--clr-white);
    }
  }
  .call-email-btns {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 20px;

    > div {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
      min-height: 70px;
      background-color: #00000020;
      padding: 15px 0px;
      gap: 10px;
      border-radius: 8px;

      a,
      p {
        color: var(--clr-white);
        font-size: 16px;
      }
    }

    svg {
      font-size: 30px;
      color: var(--clr-white);
    }
  }
  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;

    .side-by-side {
      display: grid;
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }
  .checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .agree-text,
    .agree-text > a {
      font-size: 16px;
      line-height: 22px;
      color: var(--clr-white);
    }
    .agree-text > a {
      text-decoration: underline;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-us-watermark {
    position: absolute;
    left: -30px;
    bottom: -20px;
    width: 220px;
    height: 220px;
    z-index: -1;
    opacity: 30%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 992px) {
    .main-contact-us {
      grid-template-columns: 1fr 1fr;
      gap: 100px;
      padding-left: 50px;
      padding-right: 50px;
      border-radius: 60px;
    }
    .contactus-title-container {
      p {
        font-size: 44px;
        line-height: 62px;
      }
    }
    .left-side {
      gap: 40px;
    }
    .call-email-btns {
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      > div {
        grid-template-columns: 1fr;
        min-height: 120px;
        gap: 10px;
        border-radius: 8px;

        a,
        p {
          font-size: 18px;
        }
      }

      svg {
        font-size: 40px;
      }
    }
    .form {
      gap: 20px;
      .side-by-side {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }
    .button-container {
      justify-content: flex-start;
    }
    .contact-us-watermark {
      width: 600px;
      height: 600px;
    }
  }
`;

export default ContactUs;
