import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "./../atoms";
import { navigateToSpecificComponent } from "../utils";

function Thankyou() {
  return (
    <Wrapper className="thankyou-container">
      <div className="thankyou-center wrapper page">
        <div className="card">
          <div className="icon mb-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="75"
              height="75"
              fill="currentColor"
              className="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <div className="content text-center">
            <h1>Thank You !</h1>
            <p>
              Your data has been submitted. <br />
              You'll hear from us very soon!
            </p>
            <div className="btn-container">
              <Button
                title="CLOSE"
                handleChange={(e) => navigateToSpecificComponent(e, "#home")}
              ></Button>
              {/* <button className="back-btn">
                <Link to="/">Back Home</Link>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  .thankyou-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    svg {
      color: var(--clr-primary);
    }
  }
  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;

    h1 {
      font-size: 28px;
      color: var(--clr-primary);
      text-transform: uppercase;
    }

    p {
      font-size: 14px;
    }
  }
  .card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 40px;
    background: var(--clr-white);
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    box-shadow: var(--box-shadow-dark);
    border: 5px solid var(--clr-primary);
  }
  .back-btn {
    border: none;
    background: transparent;
    margin-top: 20px;
  }
  .btn-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  /* .back-btn a {
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 4px;
    border: none;
    border: 1px solid rgb(25, 135, 84);
    cursor: pointer;
    transition: var(--transition);
    display: inline-block;
    color: rgb(25, 135, 84);

    &:hover {
      color: white;
      background-color: rgb(25, 135, 84);
    }
  } */
`;

export default Thankyou;
