import React from "react";
import styled from "styled-components";

function IconButton({
  title = "add button name",
  type = "primary",
  disabled = false,
  icon,
  handleChange,
}) {
  return (
    <Wrapper className={type} disabled={disabled} onClick={handleChange}>
      <div className="icon">{icon}</div>
      <div className="btn-name">{title}</div>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  height: 45px;
  min-width: 130px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  /* padding-left: 24px;
  padding-right: 24px; */
  text-transform: capitalize;
  cursor: pointer;
  gap: 8px;
  font-weight: var(--font-weight-500);

  &.primary {
    border: 1px solid var(--clr-primary);
    color: var(--clr-white);
    background: linear-gradient(var(--clr-primary), var(--clr-primary-light));

    .icon {
      margin-bottom: -5px;
      svg {
        font-size: 24px;
        color: var(--clr-white);
      }
    }

    .btn-name {
      border-bottom: 1px solid var(--clr-white);
    }
  }

  &.secundary {
    color: var(--clr-primary);
    background: var(--clr-white);
    border: 0px;

    .icon {
      margin-bottom: -5px;
      svg {
        font-size: 24px;
        color: var(--clr-primary);
      }
    }

    .btn-name {
      border-bottom: 1px solid var(--clr-black);
    }
  }

  @media (min-width: 992px) {
    height: 50px;
    font-size: 16px;
  }
`;

export default IconButton;
