import React from "react";
import styled from "styled-components";
import LeadingMan from "./../assets/leading-man.svg";
import LeadingPhoneOne from "./../assets/leading-phone-1.jpg";
import LeadingPhoneTwo from "./../assets/leading-phone-2.jpg";
import LeadingPhooneTwoNew from "./../assets/solutions_packaging.jpeg";

function LeadingHero() {
  return (
    <Wrapper className="leading" id="about">
      <div className="leading-center wrapper paddingTopDesktop-fifty paddingBottomDesktop-fifty paddingTopMobile-twenty paddingBottomMobile-twenty">
        <div className="leading-content">
          <div className="leading-only-text paddingBottomDesktop-fifty paddingBottomMobile-thirty">
            <div className="stegofy-heading text-center">
              <p className="special-text-color">Stegofy AR</p>
            </div>
            <div className="leading-title text-center">
              <p>
                Leading interactive WebAR platform{" "}
                <span className="special-text-color display-block">
                  Designed to empower brands like yours
                </span>
              </p>
            </div>
            <div className="leading-text text-center">
              <p>
                With Stegofy AR, you can create captivating augmented reality
                marketing content that enhances the consumer experience and
                drives unparalleled engagement.
              </p>
            </div>
          </div>

          <div className="leading-images-list">
            <div className="leading-man">
              <div className="leading-phone-one">
                <div className="leading-phone-one-img">
                  <img src={LeadingPhoneOne} alt="leading phone one" />
                </div>
              </div>
              <div className="main-img">
                <img src={LeadingMan} alt="leading man" />
              </div>
              <div className="leading-phone-two">
                <div className="leading-phone-two-img">
                  <img src={LeadingPhooneTwoNew} alt="leading phone two" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .leading-only-text {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .stegofy-heading {
    p {
      /* color: var(--clr-primary); */
      font-size: 18px;
      line-height: 26px;
      font-weight: var(--font-weight-500);
    }
  }
  .leading-title {
    p {
      font-size: 34px;
      line-height: 48px;
      color: var(--clr-black);
      font-weight: var(--font-weight-500);
    }
    /* .special {
      color: var(--clr-primary);
    } */
  }
  .leading-text {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    p {
      font-size: 16px;
      line-height: 22px;
      color: var(--clr-grey-text);
    }
  }

  @media (max-width: 991px) {
    .leading-images-list {
      padding-top: 110px;
      padding-bottom: 230px;
    }
  }

  .leading-man {
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 300px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .leading-phone-one-img,
  .leading-phone-two-img {
    width: 100%;
    height: 100%;
  }
  .leading-phone-two-img {
    border-radius: 50px;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }
  .leading-phone-one {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 140px;
    position: absolute;
    top: calc(-50% + 40px);
    left: -30px;
  }
  .leading-phone-two {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 220px;
    position: absolute;
    bottom: -70%;
    right: 0;
    margin-left: auto;
  }

  @media (min-width: 992px) {
    .leading-only-text {
      gap: 20px;
    }
    .leading-title {
      p {
        font-size: 44px;
        line-height: 62px;
        color: var(--clr-black);
      }
    }
    .leading-man {
      max-width: 450px;
      max-height: 500px;
    }
    .leading-phone-one {
      max-width: 400px;
      max-height: 260px;
      top: 0px;
      right: calc(100% - 80px);
      left: auto;
    }
    .leading-phone-two {
      max-width: 360px;
      max-height: 360px;
      bottom: 0;
      left: calc(100% - 20px);
      right: auto;
    }
  }
`;

export default LeadingHero;
