import React from "react";
import styled from "styled-components";
import { Button } from "./../atoms";
import SSymbol from "./../assets/s-symbol.svg";
import { navigateToSpecificComponent } from "./../utils";

function GetStartedHero() {
  return (
    <Wrapper className="get-started-hero wrapper paddingBottomDesktop-fifty paddingBottomMobile-twenty paddingTopDesktop-fifty paddingTopMobile-twenty">
      <div className="get-started-hero-center paddingTopDesktop-hundred paddingTopMobile-hundred paddingBottomDesktop-hundred paddingBottomMobile-hundred">
        <div className="symbol-container">
          <img src={SSymbol} alt="" />
        </div>
        <div className="get-started-title text-center">
          <p>Get started with your AR journey</p>
        </div>
        <div className="get-started-btn">
          <Button
            title="get started"
            type="secundary"
            handleChange={(e) => {
              navigateToSpecificComponent(e, "#contactus");
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .get-started-hero-center {
    /* width: 100%; */
    background: var(--clr-primary-light);
    border-radius: 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .symbol-container {
    position: absolute;
    width: 200px;
    right: 0;
    bottom: -10px;
    z-index: -1;
    opacity: 40%;

    img {
      width: 100%;
    }
  }
  .get-started-title {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    p {
      font-size: 34px;
      line-height: 48px;
      font-weight: var(--font-weight-500);
      color: var(--clr-white);
    }
  }
  .get-started-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 992px) {
    .get-started-title {
      font-size: 44px;
      line-height: 62px;
    }
    .symbol-container {
      width: 550px;
      bottom: -40px;
    }
  }
`;

export default GetStartedHero;
